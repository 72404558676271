import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from '../../../../styles/popupStyles';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';

interface PopupProps {
  open: boolean;
  onClose: Function;
  popupContent: {
    message: string;
    title: string;
    type: string;
  };
  onConfirm?: Function;
}

const ConfigPopup = ({
  open,
  onClose,
  popupContent,
  onConfirm,
}: PopupProps) => {
  const classes = useStyles();
  return (
    <Dialog open={open} fullWidth classes={{ paper: classes.infoPopup }}>
      <div className={classes.popupTitle}>
        <DialogTitle>
          <span>{popupContent?.title}</span>
        </DialogTitle>
        <IconButton aria-label="close" onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent dividers>
        {popupContent?.type === 'errorInfo' ? (
          <>
            <div
              className={`${classes.sectionContainer} ${classes.infoMessage}`}
            >
              {popupContent?.message}: <br></br>
              <br></br>
              Refer to{' '}
              <a href="https://devx.cbre.com/help/ptaas-platform/">
                <u>https://devx.cbre.com/help/ptaas-overview/</u>
              </a>
            </div>
            <div className={classes.helpInfo}>
              <HelpOutlineOutlinedIcon fontSize="small" />
              <div>
                For further query connect to DevX Team on this email Id:
                <br></br>
                <a href="mailto:!global-devx-avengers@cbre.com?subject=Query regarding Performance test as a service">
                  <u>!GLOBAL-DevX-Avengers</u>
                </a>
              </div>
            </div>
          </>
        ) : (
          <Typography>{popupContent?.message}</Typography>
        )}
      </DialogContent>
      {popupContent?.type === 'confirmRun' && (
        <DialogActions>
          <Button variant="outlined" onClick={() => onClose()} color="primary">
            Close
          </Button>
          {onConfirm && (
            <Button
              variant="contained"
              onClick={() => onConfirm()}
              color="primary"
            >
              Confirm
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ConfigPopup;
